import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <section className="section section--gradient">
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="feature-items column is-12">
                {gridItems.map(item => {
                  const Comp = item.link ? 'a' : 'div';
                  const compProps = item.link ? { href: item.link, target: '_blank', rel: 'noopener noreferrer' } : {};
                  return (
                    <Comp
                      key={item.text}
                      className="feature-item"
                      {...compProps}
                    >
                      <PreviewCompatibleImage imageInfo={item} />
                      {item.text && <p>{item.text}</p>}
                    </Comp>
                  );
                })}
              </div>
            </div>
            <div className="features-ctas">
              <Link className="button is-primary is-medium" to="/convenzioni">
                Vai alle convenzioni
              </Link>
              <Link className="button is-primary is-medium" to="/finanziamenti">
                Vai ai finanziamenti
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

export default FeatureGrid
