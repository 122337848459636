import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import classNames from 'classnames'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import omco from '../img/omco.png'

const MAIN_TIMEOUT_S = 5;

class IndexPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      index: 0,
    };
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  componentDidMount() {
    const { carosello, carosellotime } = this.props;
    const timeout = carosellotime * 1000;
    if (carosello.length <= 1) {
      return;
    }
    const changeImg = async () => {
      this.setState((prevState) => ({
        index: prevState.index === (carosello.length - 1) ? 0 : (prevState.index + 1),
      }), () => {
        this.timeout = setTimeout(changeImg, timeout);
      });
    }
    this.timeout = setTimeout(changeImg, timeout);
  }
  
  render() {
    const {
      heading,
      subheading,
      intro,
      carosello,
      html,
    } = this.props;

    const { index } = this.state;

    return (
      <div>
        <div className="full-width-image-home-wrapper">
          {carosello.map((caroselloImg, i) => {
            const className = classNames('full-width-image margin-top-0', {
              'is--in': index === i,
            });
            return (
              <img
                key={`carosello-${i}`}
                className={className}
                alt={`Studio dentistico sica immagine ${i}`}
                loading="lazy"
                src={caroselloImg.immagine.childImageSharp ? caroselloImg.immagine.childImageSharp.fluid.src : caroselloImg.immagine}
              />
            );
          })}
        </div>
        <section className="section section--gradient">
          <div className="container has-text-centered">
            <h1
              className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen main-title"
            >
              {subheading}
            </h1>
            <img src={omco} alt="Dottor Sica OMCO" style={{ height: 50 }} />
          </div>
        </section>
        <section className="section section--inverse">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="content">
                    <div className="columns">
                      <div className="column is-12">
                        <h3 className="has-text-weight-semibold is-size-2">
                          {heading}
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Features gridItems={intro.blurbs} />
        <section className="section section--inverse">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="content">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        Le ultime notizie
                      </h3>
                      <BlogRoll />
                      <div className="column is-12 has-text-centered">
                        <Link className="button is-primary is-medium" to="/notizie">
                          Leggi più notizie
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  html: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  carosello: PropTypes.array,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

IndexPageTemplate.defaultProps = {
  carosellotime: MAIN_TIMEOUT_S,
}

const IndexPage = ({ data }) => {
  const { markdownRemark: { frontmatter, html } } = data

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        html={html}
        title={frontmatter.titolo}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.descrizione}
        intro={frontmatter.intro}
        carosello={frontmatter.carosello}
        carosellotime={frontmatter.carosellotime}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        titolo
        carosello {
          immagine {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        carosellotime
        heading
        subheading
        descrizione
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            link
          }
          heading
          description
        }
      }
    }
  }
`
